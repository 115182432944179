<template>
  <CourierCompanyBase :loader_prop="loader_prop">
      <div class="package_section">
          <div class="container">
              <div class="table_wrap">
                  <div class="cell_wrap">
                      <div class="empty_package">
                          <img src="images/empty_package_box.png" alt="Package Empty">
                          <p>:)  ברוך הבא למערכת ניהול של חברת שליחויות {{name}}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </CourierCompanyBase>
</template>

<script>
import BackendModel from "../../models/BackendModel";
import CourierCompanyBase from "./CourierCompanyBase";
import('./couriercompany/css/style.css')
export default {
  name: 'CourierCompany',
  components: {
    CourierCompanyBase
  },
  data() {
    return {
      loader_prop : true,  
      email : '',
      password: '',
      id : '',
      name: '',
      view_data: []
    }
  },

  async mounted() {
    console.log("mounted")
    let backendModel = new BackendModel()
    let view_data = await backendModel.courierCompany_Request("/Api/service/backoffice/courier_company_home",{})
    console.log("view_data")
    console.log(view_data)
    console.log("view_data.data")
    console.log(view_data.data)
    console.log("iew_data.data.auth_res")
    console.log(view_data.data.auth_res)
    this.id =parseInt(view_data.data.auth_res.view_data[0].id)
    this.name = view_data.data.view_data.name
    this.loader_prop = false
  }
  
 

}

</script>

<style>
@import 'couriercompany/css/style.css';
</style>
